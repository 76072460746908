<template>
  <div>
    <div class="flexrow tiecg">
      <div class="tilelft">
        <div style="margin-right: 10px">{{ form.proName }}</div>
        <div>
          <span style="font-size: 14px; margin-right: 10px">状态:</span>
          <el-tag v-if="form.status == 1">待提交</el-tag>
          <el-tag v-else-if="form.status == 2">待受理</el-tag>
          <el-tag v-else-if="form.status == 3">待修改中</el-tag>
          <el-tag v-else-if="form.status == 4">待立项</el-tag>
          <el-tag v-else-if="form.status == 5">立项不通过</el-tag>
          <el-tag v-else-if="form.status == 6">待提交初稿</el-tag>
          <el-tag v-else-if="form.status == 7">待初稿审查</el-tag>
          <el-tag v-else-if="form.status == 8">待完善审查材料</el-tag>
          <el-tag v-else-if="form.status == 9">待提交征求意见稿</el-tag>
          <el-tag v-else-if="form.status == 10">待征求意见</el-tag>
          <el-tag v-else-if="form.status == 11">待提交送审稿及材料</el-tag>
          <el-tag v-else-if="form.status == 12">待送审</el-tag>
          <el-tag v-else-if="form.status == 13">待提交报批稿</el-tag>
          <el-tag v-else-if="form.status == 14">待报批</el-tag>
          <el-tag v-else-if="form.status == 15">已报批</el-tag>
          <el-tag v-else-if="form.status == 16">留档</el-tag>
          <!-- <el-tag v-if="nowType == 1">初审</el-tag>
          <el-tag v-if="nowType == 2">复审</el-tag>
          <el-tag v-else-if="nowType == 3">终审</el-tag>
          <el-tag v-else-if="nowType == 7">审查会</el-tag> -->
        </div>
      </div>
      <div class="tieright">
        <el-button type="primary" @click="goback">返回</el-button>
      </div>
    </div>
    <div class="flexrow">
      <div class="leftcg">
        <div class="lefttie">
          <div class="ltleft">审查资料</div>
        </div>
        <div class="leftfile" v-if="materImgList.length == 0">暂未提交材料</div>
        <div class="leftfile">
          <div class="filelistcg" v-if="materImgList">
            <div class="fileslist" v-for="(item, index) in materImgList" :key="index">
              <div v-if="index === 0" class="title">《送审稿审查报告》</div>
              <div v-if="index === 1" class="title">《标准送审稿》</div>
              <div v-if="index === 2" class="title">《编制说明》</div>
              <div v-if="index === 3" class="title">《报批表》</div>
              <div v-if="index === 4" class="title">《标准征求意见汇总表》</div>
              <div v-if="index === 5" class="title">《编制人员名单》</div>
              <div v-if="index === 6" class="title">《变更申请》</div>
              <div class="flexrow cgfiles">
                <div class="cgfleft">
                  <el-tooltip class="item" effect="light" :content="item.name" placement="bottom">
                    <div class="cgfleftto">{{ item.name }}</div>
                  </el-tooltip>
                </div>
                <div class="cgfrig" v-if="item.url.endsWith('.doc') || item.url.endsWith('.docx')">
                  <span style="color: #509bfd;cursor: pointer;" @click="zhanshi(item)">下载</span>
                  <!-- <a  
                  :href="showFileSrc"
                  target="_blank"
                  class="ltright"
                  style="color: #509bfd; text-decoration: none"
                  >下载</a
                > -->
                </div>
                <div class="cgfrig" @click="viewFile(item.url)" v-else>查看</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="centercg">
        <div class="lefttie">
          <div class="ltleft">文件预览</div>

          <div class="ltright" v-if="showFileSrc"><a :href="showFileSrc" target="_blank" class="ltright">全屏预览</a></div>
        </div>
        <div v-if="showFileSrc.length == 0" class="fileiframe">暂未文件预览</div>
        <div v-if="showFileSrc.length > 0" class="fileiframe">
          <div ref="docx_dom" class="iframe" v-if="showFileType == 1"></div>
          <iframe frameborder="0" class="iframe" v-else-if="showFileType == 2" :src="showFileSrc"></iframe>
        </div>
      </div>
      <div class="rightcg">
        <div class="lefttie" style="margin-bottom: 10px; border-bottom: none">
          <div class="ltleft">审查意见</div>
        </div>
        <div>
          <el-timeline :reverse="reverse">
            <el-timeline-item v-for="(activity, index) in activities" :key="index" :style="index == activities.length - 1
                ? 'color: #3082e2'
                : 'color: #999999'
              ">
              <div class="flex-row">
                <div class="checktitle" style="color: #999999">
                  {{ activity.type | shenchaType }}
                </div>
                <div class="shehe">审核人：{{ activity.uname }}</div>
              </div>
              <div class="kuang">
                <div class="cr-card">
                  <div class="checkcont">
                    {{ activity.content }}
                  </div>
                  <div class=" chfiles">
                    <div class="cgfleft" v-for="(item, index) in activeFile(activity.type)" :key="index">
                      <el-tooltip class="item" effect="light" :content="item.name" placement="bottom">
                        <div class="cgfleftto" @click="zhanshi(item)">{{ item.name }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="linetie">
                  <div class="lttime">
                    <!-- <i class="el-icon-time"></i> -->
                    {{ activity.created_at | formatDate }}
                  </div>
                </div>
              </div>
            </el-timeline-item>

            <el-timeline-item v-if="nowType != -1" color="#3082e2">
              <div class="checktitle" style="color: #3082e2">
                {{ nowType | shenchaType }}
              </div>
              <div class="kuang">
                <div class="cr-card">
                  <div class="checkcont" v-if="nowType != 7">
                    <!-- {{  nowType}} -->
                    <el-input type="textarea" :rows="2" style="min-height: 100px" placeholder="请输入审查意见" v-model="content">
                    </el-input>
                  </div>
                  <div class="flexrow filebtn">
                    <div class="ltmanger">
                      <div v-for="(item, index) in materImgList2" :key="index" class="listrow">
                        <div style="width: 90%" class="listrownam">
                          {{ item.name }}
                        </div>
                        <div v-if="materImgList2.length > 0">
                          <i @click="delfiles(index)" class="el-icon-close" style="color: #f56c6c; cursor: pointer"></i>
                        </div>
                      </div>
                    </div>
                    <div class="lttime">
                      <el-upload :data="{
                        token: $store.state.token,
                      }" :file-list="materImgList2" :on-success="(response, file, fileList) =>
    changeMaterImg(1, response, file, fileList)
  " v-if="form.status > 11" :show-file-list="false" accept=".pdf,.jpg,.png,.doc,.docx"
                        action="https://api.gbservice.cn/api/public/bupload" class="upload-demo">
                        <el-button size="small" type="primary" style="height: 40px">上传
                        </el-button>
                      </el-upload>
                      <el-upload :data="{
                        token: $store.state.token,
                      }" :file-list="materImgList2" :on-success="(response, file, fileList) =>
    changeMaterImg(1, response, file, fileList)
  " v-else disabled :show-file-list="false" accept=".pdf,.jpg,.png,.doc,.docx"
                        action="https://api.gbservice.cn/api/public/bupload" class="upload-demo">
                        <el-button size="small" type="primary" style="height: 40px" disabled>上传
                        </el-button>
                      </el-upload>
                    </div>
                  </div>
                  <div class="flowrow">
                    <div class="flowleft">意见模板</div>
                    <div class="flowright">
                      <i class="el-icon-circle-plus-outline" @click="dialogVisible = true"></i>
                    </div>
                  </div>
                  <div class="checkcont moban">
                    <div v-for="(item, index) in mblist" :key="index">
                      <div class="mbline">
                        <div class="neirong">
                          <el-tooltip class="item" effect="light" :content="item.content" placement="bottom">
                            <div class="neirongto" @click="changeContent(item)">
                              {{ item.content }}
                            </div>
                          </el-tooltip>
                        </div>
                        <div class="delbtn" @click="delmblist">
                          <i class="el-icon-close" style="color: #f56c6c"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
          <div class="flexrow tgbtn">
            <el-button type="success" disabled @click="tosus" v-if="form.status == 13 || form.status == 11">{{ pass
            }}</el-button>
            <el-button type="success" :disabled="disabled" @click="tosus" v-else>{{ pass }}</el-button>
            <el-button type="danger" @click="tuihui" disabled v-if="form.status == 13 || form.status == 11">退回</el-button>
            <el-button type="danger" @click="tuihui" :disabled="disabled" v-else>退回</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="意见模板" :visible.sync="dialogVisible" width="50%">
      <el-input type="textarea" :rows="2" placeholder="请输入初审意见" v-model="mbcontent">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMuban">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getBiaozhunDetail,
  addExam,
  spRizhi,
  spDetail,
  addmuban,
  mubanlist,
  delmuban,
} from "../../../../api/project";
import axios from "axios";
import {
  roleType
} from "../../../../api/user";
let docx = require("docx-preview");
export default {
  filters: {
    shenchaType(type) {
      if (type == 1 || type == 4) {
        return "初审";
      } else if (type == 2 || type == 5) {
        return "复审";
      } else if (type == 3 || type == 6) {
        return "终审";
      }

    },
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd hh:mm:ss";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      materImgList: [],
      materImgList2: [],
      materImgList4: [],
      reverse: false,
      form: {},
      activities: [{}],
      content: "",
      textarea: "",
      showFileSrc: "",
      showFileType: "",
      pass: "通过",
      nowType: 1,
      disabled: false,
      dialogVisible: false,
      mbcontent: "",
      mblist: [],
      materImgList3: [],
      userRoleType: []
    };
  },
  mounted() {
    this.getIdAndToken();
    this.mubanList();
  },
  methods: {
    getRoleType() {
      roleType({ token: this.token }).then(res => {
        this.userRoleType = res.data.role_type
        this.showNowType();
      })
    },
    zhanshi(item) {
      // this.dialogfiles = true;
      // this.viewFile(row.url, row.name)
      window.open(item.url);
    },
    activeFile(type) {
      if (type == 1 || type == 4) {
        return this.materImgList4.filter((item) => item.type == 37);
      } else if (type == 2 || type == 5) {
        return this.materImgList4.filter((item) => item.type == 38);
      } else if (type == 3 || type == 6) {
        return this.materImgList4.filter((item) => item.type == 39);
      } else if (type == 7) {
        return this.materImgList4.filter((item) => item.type == 40);
      }
    },
    delfiles(index) {
      this.materImgList2.splice(index, 1);
    },
    changeMaterImg(index, response, file, fileList) {
      this.materImgList2.push(response.data);
      this.form.filename = this.materImgList2
        .map((item) => item.name)
        .join(",");
    },
    delmblist() {
      let mbid = this.mblist[0].id;
      let params = {
        token: this.$store.state.token,
        id: mbid,
      };
      delmuban(params).then((res) => {
        this.$message.success("删除成功");
        this.dialogVisible = false;
        this.getIdAndToken();
        this.mubanList();
      });
    },
    changeContent(item) {
      this.content = item.content;
    },
    addMuban() {
      let params = {
        token: this.$store.state.token,
        content: this.mbcontent,
        type: "1",
      };
      addmuban(params).then((res) => {
        console.log("模板", res);
        this.$message.success("添加成功");
        this.dialogVisible = false;
        this.getIdAndToken();
      });
    },
    mubanList() {
      let params = {
        token: this.$store.state.token,
        page: 1,
        limit: 15,
      };
      mubanlist(params).then((res) => {
        this.mblist = res.data;
      });
    },
    showNowType() {
      let lastType = 0;
      if (this.activities.length > 0) {
        lastType = this.activities[this.activities.length - 1].type;
      }
      if (lastType == 0 || lastType == 4 || lastType == 5 || lastType == 6) {
        this.nowType = 1;
        if (this.userRoleType.includes('1')) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      } else if (lastType == 1) {
        this.nowType = 2;
        if (this.userRoleType.includes('2')) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      } else if (lastType == 2) {
        this.nowType = 3;
        if (this.userRoleType.includes('3')) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      } else if (lastType == 3) {
        this.nowType = -1;
      }
      // else {
      //   this.nowType = -1;
      // }
    },
    viewFile(src) {
      this.showFileSrc = src;
      if (this.showFileSrc.lastIndexOf(".") != -1) {
        if (
          this.showFileSrc
            .substring(this.showFileSrc.lastIndexOf("."))
            .indexOf("doc") != -1
        ) {
          this.showFileType = 1;
          this.$nextTick(function () {
            axios({
              method: "get",
              responseType: "blob", // 设置响应文件格式
              url: src,
            }).then(({ data }) => {
              docx.renderAsync(data, this.$refs.docx_dom); // 获取到biob文件流，进行渲染到页面预览
            });
          });
        } else {
          this.showFileType = 2;
        }
      } else {
        this.showFileType = "";
      }
    },
    checkdetail() {
      let params = {
        token: this.$store.state.token,
        id: this.$route.query.id,
      };
      spDetail(params).then((res) => {
        if (this.type == "1") {
          this.$message.success("初审成功");
        }
      });
    },
    checkrz() {
      let params = {
        token: this.$store.state.token,
        id: this.$route.query.id,
      };
      spRizhi(params).then((res) => {
        if (this.type == "1") {
          this.$message.success("初审成功");
        }
      });
    },
    tuihui() {
      let type = 0;
      if (this.nowType == 1) {
        type = 4;
      } else if (this.nowType == 2) {
        type = 5;
      } else if (this.nowType == 3) {
        type = 6;
      }
      let params = {
        token: this.$store.state.token,
        sid: this.$route.query.id,
        status: "11",
        after_status: "11",
        content: this.content,
        type: type,
      };
      if (this.nowType == 3) {
        params.after_status = 16;
      }
      this.disabled = true;
      addExam(params)
        .then((res) => {
          if (this.nowType == 7) {
            this.$message.success("标准终止，标准信息留档");
          } else {
            this.$message.success("退回到用户");
          }
        })
        .catch((err) => {
          this.disabled = true;
        });
    },
    tosus() {
      if (this.nowType == "1" || this.nowType == "2") {
        if (this.nowType == "1") {
          this.materImgList2.map((item) => (item.type = 37));
        }
        if (this.nowType == "2") {
          this.materImgList2.map((item) => (item.type = 38));
        }
        // if (this.nowType == "3") {
        //   this.materImgList2.map((item) => (item.type = 39));
        // }
      }
      if (this.nowType == "1" || this.nowType == "2") {
        let params = {
          token: this.$store.state.token,
          sid: this.$route.query.id,
          status: "12",
          content: this.content,
          type: this.nowType,
          files: this.materImgList2,
        };
        this.disabled = true;
        this.pass = "已审核通过";
        addExam(params)
          .then((res) => {
            console.log("提交审批", res);
            if (this.nowType == "1") {
              this.$message.success("初审成功");
            }
            if (this.nowType == "2") {
              this.$message.success("复审成功");
            }
            // if (this.nowType == "3") {
            //   this.$message.success("终审成功");
            // }
          })
          .catch((err) => {
            this.disabled = false;
          });
      }
      if (this.nowType == "3") {
        if (this.nowType == "3") {
          this.materImgList2.map((item) => (item.type = 40));
        }
        let params = {
          token: this.$store.state.token,
          sid: this.$route.query.id,
          status: "12",
          content: this.content,
          type: this.nowType,
          after_status: "13",
          files: this.materImgList2,
        };
        this.disabled = true;
        addExam(params)
          .then((res) => {
            console.log("审查会通过", res);
            this.$message.success("审查会通过");
          })
          .catch((err) => {
            this.disabled = false;
          });
      }
    },
    goback() {
      this.$router.go(-1);
    },
    getIdAndToken() {
      this.id = this.$route.query.id;
      this.token = this.$store.state.token; //获取token
      this.getInfo();
    },
    //获取详情
    getInfo() {
      getBiaozhunDetail({ id: this.id, token: this.token })
        .then((res) => {
          this.htmlTitle = res.data.proName;
          this.form = res.data;
          this.tableData = res.data.fu; //主要起草人员展开无分页
          this.activities = res.data.examines;
          this.materImgList = res.data.files.filter(
            (item) =>
              item.type == 9 ||
              item.type == 10 ||
              item.type == 11 ||
              item.type == 12 ||
              item.type == 13 ||
              item.type == 14 ||
              item.type == 15 ||
              item.type == 16
          );
          this.materImgList4 = res.data.files.filter(
            (item) =>
              item.type == 37 ||
              item.type == 38 ||
              item.type == 39 ||
              item.type == 40
          );
          if (this.materImgList && this.materImgList.length > 0) {
            this.viewFile(this.materImgList[0].url);
          }
          this.activities = res.data.examines.filter(
            (item) => item.status == 12 || item.status == 11
          );
          this.dqtag = this.activities.slice(-1);
          // this.showNowType();
          this.getRoleType()
          this.tableData2[0].start_time = res.data.start_time1;
          this.tableData2[0].endtime = res.data.endtime1;
          this.tableData2[1].start_time = res.data.start_time2;
          this.tableData2[1].endtime = res.data.endtime2;
          this.tableData2[2].start_time = res.data.start_time3;
          this.tableData2[2].endtime = res.data.endtime3;
          this.tableData2[3].start_time = res.data.start_time4;
          this.tableData2[3].endtime = res.data.endtime4;
          this.tableData2[4].start_time = res.data.start_time5;
          this.tableData2[4].endtime = res.data.endtime5;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
/deep/.el-timeline-item__node--normal {
  margin-top: 8px;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #ffffff;
}

/deep/.el-textarea__inner {
  /* border: none; */
  min-height: 100px !important;
}

/deep/.el-textarea {
  height: 100px;
}

/deep/.el-timeline {
  padding-left: 10px;
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}

.filebtn {
  margin: 10px 0;
}

.el-timeline::-webkit-scrollbar {
  display: none;
}
</style>
<style scoped lang="scss">
.cgfleftto:hover {
  color: #3082e2;
  cursor: pointer;
}

.chfiles {
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
  padding-top: 10px;
}

.listrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.listrownam {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ltmanger {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  width: 80%;
}

.shehe {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  padding-right: 10px;
  width: 37%;
}

.flowrow {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;

  .flowleft {
    font-size: 16px;
    font-weight: 400;
    color: #3082e2;
    margin-right: 10px;
    cursor: pointer;
  }

  .flowright {
    color: #3082e2;
    margin-top: 2px;
    cursor: pointer;
  }
}

.mbline {
  height: 35px;
  line-height: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .neirong {
    width: 80%;
    cursor: pointer;
  }

  .neirongto {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .delbtn {
    cursor: pointer;
  }
}

.checktitle {
  font-size: 18px;
  font-weight: bold;
  color: #999999;
  padding-top: 3px;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tiecg {
  margin-bottom: 20px;
}

.lefttie {
  border-bottom: 1px solid #dbe9ef;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ltleft {
    border-left: 4px solid #3082e2;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #3082e2;
  }

  .ltright {
    font-size: 14px;
    color: #509bfd;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
}

.leftcg {
  width: 19%;
  background: #ffffff;
}

.centercg {
  width: 55%;
  background: #ffffff;
}

.rightcg {
  width: 24%;
  padding-right: 20px;
}

.linetie {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-top: 15px;

  .lttime {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
}

.kuang {
  background-color: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.29);
  margin: 10px;
  padding: 10px 15px;
}

.cr-card {
  .checkcont {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    // border-bottom: 1px solid #dbe9ef;
  }
}

.tgbtn {
  padding: 25px 58px;
}

.cgfleftto {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.leftfile {
  padding: 10px;

  .filelistcg {
    .fileslist {
      .title {
        font-size: 16px;
        text-align: left;
        padding-bottom: 11px;
        // white-space:nowrap;
      }
    }

    .cgfiles {
      height: 54px;
      background: #f4f4f4;
      line-height: 54px;
      padding: 0 20px;
      margin-bottom: 10px;

      .cgfleft {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        width: 80%;
      }

      .cgfrig {
        font-size: 14px;
        font-weight: 400;
        color: #509bfd;
        cursor: pointer;
      }

      .cgfrig:hover {
        color: #84b5ec;
      }
    }
  }
}

.fileiframe {
  height: 74vh;
  padding: 10px 1%;
  // overflow: auto;

  .iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
